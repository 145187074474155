<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card pt-4 mb-xl-9">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder" v-if="this.user && this.user.userprofile">
                            {{ $t("pages.roadmap.userRoadmaps") }} <span class="text-primary">{{ this.user.userprofile.lastname }} {{ this.user.userprofile.firstname }}</span>
                        </h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">
                            {{ $t("pages.roadmap.desc") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card pt-4 mb-xl-9">
                <div class="card-body">
                    <div class="table-responsive" id="printableTable">
                        <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <thead>
                                <tr class="fw-bolder text-muted">
                                    <th class="min-w-120px">{{ $t("pages.roadmap.action") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.deadline") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.contributor") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.impact") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.status") }}</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loading">
                                <tr v-for="action in roadmaps" v-bind:key="action.id">
                                    <td>
                                        <span class="text-muted fw-bold text-muted d-block fs-7">{{ action.category.label }}</span>
                                        <a href="#" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"> {{ action.label }}</a>
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ $moment(action.deadline).format("DD/MM/YYYY") }}
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ action.collaborators }}
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ action.impact }}
                                    </td>
                                    <td>
                                        <span v-if="action.status === 'TODO'" class="badge-light-primary badge text-default">{{ $t("pages.roadmap.todo") }}</span>
                                        <span v-else-if="action.status === 'DONE'" class="badge-light-success badge text-success">{{ $t("pages.roadmap.done") }}</span>
                                        <span v-else-if="action.status === 'INPR'" class="badge-light-warning badge text-warning">{{ $t("pages.roadmap.inProgress") }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="loading">
                            <div class="d-flex row-auto flex-center w-100 h-200px">
                                <span class="spinner-border text-primary" role="status"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import XLSX from "xlsx";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "roadmap",
    components: {},
    data: function () {
        return {
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            roadmaps: [],
            user: {} as any,
            categories: [],
            loading: true,
        };
    },
    methods: {
        downloadRoadmap() {
            if (this.roadmaps && this.roadmaps.length > 0) {
                let suitableJson = this.roadmaps.map((action: any) => {
                    let status = "A faire";
                    if (action.status === "DONE") {
                        status = "Terminée";
                    } else if (action.status === "INPR") {
                        status = "En cours";
                    }
                    return {
                        Thématique: action.category.label,
                        Action: action.label,
                        Collaborateurs: action.collaborators,
                        Échéance: action.deadline,
                        Status: status,
                        Commentaires: action.commentary,
                    };
                });
                const data = XLSX.utils.json_to_sheet(suitableJson);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, "data");
                XLSX.writeFile(wb, "roadmaps.xlsx");
            }
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        getUserRoadmaps() {
            roadMapApi
                .getUserRoadmaps(this.$route.params.user_id)
                .then((response) => {
                    this.roadmaps = response.data["roadmaps"];
                    this.user = response.data["user"];
                    this.loading = false;
                    setCurrentPageBreadcrumbs(this.t("pages.roadmap.userRoadmaps") + this.user.userprofile.lastname + " " + this.user.userprofile.firstname, [
                        {
                            link: "/roadmap/",
                            label: this.t("pages.roadmap.title"),
                        },
                        {
                            link: "/roadmap/related-roadmap/",
                            label: this.t("pages.roadmap.availableRoadmaps"),
                        },
                    ]);
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.title"), []);

        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary,
                },
            });
        }

        return { t, editRoadmap };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.userRoadmaps") + this.user.userprofile.lastname + " " + this.user.userprofile.firstname, [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
                {
                    link: "/roadmap/related-roadmap/",
                    label: this.t("pages.roadmap.availableRoadmaps"),
                },
            ]);
        },
    },
    created() {
        this.getUserRoadmaps();
    },
});
</script>
